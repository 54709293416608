import NextImage from 'next/image'
import React, { ReactNode, useEffect, useState } from 'react'
import styled, { useTheme } from 'styled-components'

import { Container, Icon, Text, Carousel } from 'common/UI'
import { CardItemStoryblok, CardsStoryblok } from 'common/types'
import {
  getImageAttributes,
  hasValidLink,
  textByLine,
} from 'common/utils/content'
import { addAlphaToColor, dashedLine } from 'common/utils/style'
import { CMSLink } from 'modules/shared'

import { Editable } from './Editable'

type Props = {
  block: CardsStoryblok
}

type CardWrapperProps = {
  card: CardItemStoryblok
  children: ReactNode
}

const CardWrapper = ({ card, children }: CardWrapperProps): JSX.Element => {
  if (card.link && hasValidLink(card.link))
    return (
      <CMSLink href={card.link} linkStyle="text" css={{ width: '100%' }}>
        {children}
      </CMSLink>
    )

  return <div>{children}</div>
}

const GridTitleSection = ({
  title,
  description,
}: {
  title?: string
  description?: string
}) => {
  return (
    <>
      {(title || description) && (
        <Container>
          {title &&
            textByLine(title, (part) => {
              return (
                <Text
                  as="h2"
                  variant="title"
                  css={{ marginBottom: '1.5rem', textAlign: 'center' }}
                >
                  {part}
                </Text>
              )
            })}
          {description &&
            textByLine(description, (part) => {
              return (
                <Text
                  as="p"
                  variant="eighteen"
                  css={{ marginBottom: '2rem', textAlign: 'center' }}
                >
                  {part}
                </Text>
              )
            })}
        </Container>
      )}
    </>
  )
}

const SliderCards = ({ block }: Props): JSX.Element => {
  const {
    cards,
    title,
    description,
    button_label,
    button_link,
    has_padding,
    variant,
  } = block
  const [isLarge, setIsLarge] = useState<boolean>(false)
  const theme = useTheme()
  const hasText = title || description

  const renderText = () => {
    return (
      <>
        {title &&
          textByLine(title, (part) => {
            return (
              <Text as="h2" variant="title" css={{ marginBottom: '1rem' }}>
                {part}
              </Text>
            )
          })}
        <div style={{ marginTop: 'auto' }}>
          {description &&
            textByLine(description, (part) => {
              return (
                <Text as="p" variant="eighteen">
                  {part}
                </Text>
              )
            })}
        </div>

        {button_label && button_link && hasValidLink(button_link) && (
          <div style={{ marginTop: '2rem' }}>
            <CMSLink variant="ghost" href={button_link}>
              {button_label}
            </CMSLink>
          </div>
        )}
      </>
    )
  }

  const renderCards = () => {
    return cards.map((card) => {
      const { src, alt } = getImageAttributes(
        card.image,
        '400x400/smart/filters:format(webp)'
      )

      return (
        <Editable block={card} key={card._uid}>
          <CardWrapper card={card}>
            <SliderCard variant={variant}>
              <SliderCardImage has_padding={has_padding} variant={variant}>
                {card.image?.filename && (
                  <div
                    css={{
                      position: 'relative',
                      width: '100%',
                      height: '100%',
                    }}
                  >
                    <NextImage
                      fill
                      sizes="400px"
                      alt={alt}
                      src={src}
                      style={{ objectFit: 'cover' }}
                    />
                  </div>
                )}
              </SliderCardImage>

              <SliderCardText>
                <Text
                  as={card.title_variant ? card.title_variant : 'h2'}
                  variant="title/small/regular"
                  css={{
                    textOverflow: 'ellipsis',
                    overflow: 'hidden',
                    width: '100%',
                  }}
                >
                  {card.title}
                </Text>
                {card.description && (
                  <Text as="h3" variant="sixteen">
                    {card.description}
                  </Text>
                )}
              </SliderCardText>
            </SliderCard>
          </CardWrapper>
        </Editable>
      )
    })
  }

  useEffect(() => {
    function handleResize() {
      setIsLarge(
        window.matchMedia(`(min-width: ${theme.breakpoints.lg})`).matches
      )
    }

    handleResize()
    window.addEventListener('resize', handleResize)

    return () => {
      window.removeEventListener('resize', handleResize)
    }
  }, [theme.breakpoints.lg])

  return (
    <div>
      <Container>
        <SliderTextMobile>{renderText()}</SliderTextMobile>
      </Container>
      <Carousel itemsPerView={variant == 'slider' ? 3 : 4}>
        {isLarge && hasText ? (
          <SliderTextDesktop>{renderText()}</SliderTextDesktop>
        ) : null}
        {renderCards()}
      </Carousel>
    </div>
  )
}

const GridCards = ({ block }: Props): JSX.Element => {
  const { title, description, cards } = block

  return (
    <>
      <GridTitleSection title={title} description={description} />
      <GridContainer>
        {cards.map((card) => {
          const { src, alt } = getImageAttributes(
            card.image,
            '400x400/smart/filters:format(webp)'
          )

          return (
            <Editable block={card} key={card._uid}>
              <CardWrapper card={card}>
                <GridCard>
                  <GridCardImage>
                    {card.image?.filename && (
                      <NextImage
                        fill
                        sizes="400px"
                        alt={alt}
                        src={src}
                        style={{ objectFit: 'cover' }}
                      />
                    )}
                  </GridCardImage>
                  <GridCardText>
                    <Text
                      as={card.title_variant ? card.title_variant : 'h2'}
                      variant="title/small/regular"
                    >
                      {card.title}
                    </Text>
                    <GridCardDescription>
                      {card.description && (
                        <Text as="h3" variant="eighteen">
                          {card.description}
                        </Text>
                      )}
                    </GridCardDescription>
                    {card.link && hasValidLink(card.link) && (
                      <Icon styles={{ marginTop: '1rem' }} icon="arrow-right" />
                    )}
                  </GridCardText>
                </GridCard>
              </CardWrapper>
            </Editable>
          )
        })}
      </GridContainer>
    </>
  )
}

const SmallGridCards = ({ block }: Props): JSX.Element => {
  const { cards, title, description } = block

  return (
    <>
      <GridTitleSection title={title} description={description} />
      <GridContainer>
        {cards.map((card) => {
          const { src, alt } = getImageAttributes(
            card.image,
            '400x400/smart/filters:format(webp)'
          )

          return (
            <Editable block={card} key={card._uid}>
              <CardWrapper card={card}>
                <SmallGridCard>
                  {card.image?.filename && (
                    <GridCardImage
                      css={{
                        borderRadius: '0.75rem',
                      }}
                    >
                      <NextImage
                        fill
                        sizes="400px"
                        alt={alt}
                        src={src}
                        style={{ objectFit: 'cover' }}
                      />
                    </GridCardImage>
                  )}

                  {card.title && (
                    <Text
                      as={card.title_variant ? card.title_variant : 'h2'}
                      variant="title/small/regular"
                      css={{ marginTop: '1rem' }}
                    >
                      {card.title}
                    </Text>
                  )}

                  {card.description && (
                    <GridCardDescription>
                      <Text as="h3" variant="eighteen">
                        {card.description}
                      </Text>
                    </GridCardDescription>
                  )}
                </SmallGridCard>
              </CardWrapper>
            </Editable>
          )
        })}
      </GridContainer>
    </>
  )
}

export const Cards = ({ block, ...props }: Props): JSX.Element => {
  const { variant } = block
  const variants = {
    slider: SliderCards,
    grid: GridCards,
    'grid-small': SmallGridCards,
    'slider-small': SliderCards,
  }

  const CardsComponent = variants[variant]

  return (
    <Section variant={variant} {...props}>
      <CardsComponent block={block} />
    </Section>
  )
}

const Section = styled.div<{ variant: CardsStoryblok['variant'] }>`
  margin: 2rem auto;

  ${({ theme }) => theme.media.md} {
    margin: 3rem auto;
  }
  ${({ theme }) => theme.media.lg} {
    margin: ${({ variant }) =>
      variant === 'slider-small' ? '2.5rem auto' : '4.5rem auto'};
  }
`

const SliderText = styled.div`
  ${({ theme }) => theme.media.lg} {
    width: 100%;
    height: 100%;
    margin-right: 2rem;
    padding-right: 2rem;
    flex-shrink: 0;
  }
`
const SliderTextMobile = styled(SliderText)`
  display: block;
  margin-bottom: 2rem;

  ${({ theme }) => theme.media.lg} {
    display: none;
  }
`

const SliderTextDesktop = styled(SliderText)`
  display: none;

  ${({ theme }) => theme.media.lg} {
    display: flex;
    flex-direction: column;
  }
`

const SliderCard = styled.div<{
  variant: CardsStoryblok['variant']
}>`
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
  flex-shrink: 0;

  background-color: ${({ theme }) =>
    addAlphaToColor(theme.colors.foreground.default, 5)};
  border-radius: 0.75rem;

  overflow: hidden;

  max-width: ${({ variant }) =>
    variant === 'slider-small' ? '19.0625rem' : '100%'};

  transition: opacity ${({ theme }) => theme.transitions.ease('150ms')};

  @media (hover: hover) {
    &:hover {
      img {
        transform: scale(1.05);
      }
    }
  }
`

const SliderCardImage = styled.div<{
  has_padding?: boolean
  variant: CardsStoryblok['variant']
}>`
  display: flex;
  justify-content: center;
  align-items: center;
  overflow: hidden;

  height: 20rem;
  width: 100%;

  margin-left: auto;

  & img {
    transition: transform ${({ theme }) => theme.transitions.cubic()};
  }

  ${({ theme }) => theme.media.lg} {
    height: ${({ variant }) => (variant === 'slider' ? '25rem' : '15.25rem')};
  }
`

const SliderCardText = styled.div`
  ${({ theme }) =>
    dashedLine('top', addAlphaToColor(theme.colors.foreground.default, 20))};
  padding: 1rem;
  margin-top: auto;
  flex: 1;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
`

const GridContainer = styled(Container)`
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(16rem, 1fr));
  grid-gap: 2rem;

  align-items: flex-start;
`

const GridCard = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;

  border-radius: 0.75rem;
  overflow: hidden;

  background-color: ${({ theme }) =>
    addAlphaToColor(theme.colors.foreground.default, 5)};

  transition: opacity ${({ theme }) => theme.transitions.ease('150ms')};
  @media (hover: hover) {
    &:hover {
      img {
        transform: scale(1.05);
      }
    }
  }
`

const SmallGridCard = styled.div`
  width: 100%;

  transition: opacity ${({ theme }) => theme.transitions.ease('150ms')};

  @media (hover: hover) {
    &:hover {
      img {
        transform: scale(1.05);
      }
    }
  }
`

const GridCardImage = styled.div`
  padding-bottom: 100%;
  width: 100%;
  overflow: hidden;
  position: relative;

  display: flex;
  justify-content: center;
  align-items: center;
  overflow: hidden;

  & img {
    transition: transform ${({ theme }) => theme.transitions.cubic()};
  }
`

const GridCardText = styled.div`
  padding: 1.5rem 1rem 1rem;
  margin-top: auto;
`

const GridCardDescription = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;

  margin-top: 0.5rem;
`
